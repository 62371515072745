import DefaultIcon from '@mui/icons-material/ViewList';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { useCreatePath, useGetResourceLabel } from 'ra-core';
import { LayoutProps } from 'ra-ui-materialui';
import { createElement } from 'react';
import {
  AppBar,
  AppBarProps,
  Layout,
  Logout,
  Menu,
  MenuItemLink,
  UserMenu,
  useLogout,
  usePermissions,
  useResourceDefinitions,
} from 'react-admin';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Admin_Role_Type_Enum } from 'src/utils/enum';
import BackButton from './BackButton';

export const LayoutWrapper = (props: LayoutProps): JSX.Element => {
  return (
    <>
      <Layout
        sx={{ '& .RaLayout-content': { paddingRight: 4, paddingLeft: 4 } }}
        {...props}
        appBar={CustomAppBar}
        menu={CustomMenu}
      />
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
};

const CustomAppBar = (props: AppBarProps): JSX.Element => {
  const isLargeEnough = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));

  return (
    <AppBar {...props} color="secondary" position={'fixed'} elevation={1} userMenu={<CustomUserMenu />}>
      <BackButton />
      <Typography
        variant="h6"
        color="inherit"
        sx={{
          flex: 1,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
        id="react-admin-title"
      />

      {/* {isLargeEnough && (
        <Box
          sx={{
            flex: 1,
            textAlign: 'center',
            '& svg': {
              width: '112px',
              height: '20px',
              '@media (min-width:600px)': {
                width: '160px',
                height: '17.5px',
              },
            },
          }}
        >
          <img style={{ height: '36px', width: 'auto' }} src={process.env.PUBLIC_URL + '/logo.svg'} />
        </Box>
      )} */}
      {isLargeEnough && <Box component="span" sx={{ flex: 1 }} />}
    </AppBar>
  );
};

const CustomUserMenu = () => (
  <UserMenu>
    <Logout />
  </UserMenu>
);

const activeMenu = {
  admin: ['*'],
  admin_manager: ['*'],
};
const CustomMenu = () => {
  const resources = useResourceDefinitions();
  const { permissions }: { permissions: string } = usePermissions();

  const menus = permissions === Admin_Role_Type_Enum.AdminManager ? activeMenu.admin_manager : activeMenu.admin;
  const getResourceLabel = useGetResourceLabel();
  const createPath = useCreatePath();
  const logout = useLogout();
  const handleLogoutClick = () => logout();

  const resourceLabel = (name: string) => {
    if (name === 'log') {
      return 'System logs';
    }
    if (name === 'subscriptions') {
      return ' Subscriptions';
    }
    if (name === 'order') {
      return 'Orders';
    }
    if (name === 'caldav') {
      return 'Calendar config';
    }
    if (name === 'created_event') {
      return 'Event list';
    }
    if (name === 'tenant_mini_server_view') {
      return 'Mini-server';
    }

    if (name === 'extensions') {
      return 'Extensions MPN';
    }

    if (name === 'sb_config') {
      return 'Smoobu config';
    }
    if (name === 'sb_log') {
      return 'Smoobu logs';
    }
    if (name === 'guests') {
      return 'Guest App Guests';
    }
    if (name === 'guest_app_service_orders') {
      return 'Guest App Service Orders';
    }
    if (name === 'guest_app_services') {
      return 'Guest App Services';
    }
    return getResourceLabel(name);
  };
  return (
    <Menu>
      {Object.keys(resources)
        .filter((name) => resources[name].hasList && (menus[0] === '*' || menus.includes(name)))
        .map((name) => (
          <MenuItemLink
            key={name}
            to={createPath({
              resource: name,
              type: 'list',
            })}
            state={{ _scrollToTop: true }}
            primaryText={resourceLabel(name)}
            leftIcon={resources[name].icon ? createElement(resources[name].icon) : <DefaultIcon />}
          />
        ))}
      <MenuItemLink to={'#'} primaryText={'Logout'} onClick={handleLogoutClick} leftIcon={<DefaultIcon />} />
    </Menu>
  );
};
