import PropTypes from 'prop-types';
import { DateField, Identifier, NumberField, ReferenceField, Show, SimpleShowLayout, TextField } from 'react-admin';
import { JsonDataViewField } from '../logs/JsonDataField';

const GustAppServiceOrderViewEmbedded = ({ id }: { id?: Identifier }) => {
  return (
    <Show id={id} queryOptions={{ refetchInterval: 5000 }}>
      <SimpleShowLayout>
        <TextField source="id" />
        <ReferenceField source="tenant_id" reference="tenant_mini_server_view" label="Mini-server" link="show">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="guest_id" reference="guests" label="Guest" link="show">
          <TextField source="guest_name" />
        </ReferenceField>
        <NumberField source="amount" label="Amount" />
        <TextField source="payment_intent_status" label="Status" />
        <TextField source="guest_note" label="Guest note" />
        <TextField source="host_note" label="Host note" />
        <NumberField source="amount" label="Amount" />
        <JsonDataViewField customFieldName="order_data" label="Order data" />
        <TextField source="payment_intent_id" label="Stripe payment intent id" />
        <DateField source="updated_at" showTime locales="de-AT" />
      </SimpleShowLayout>
    </Show>
  );
};

GustAppServiceOrderViewEmbedded.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
  id: PropTypes.string,
};

export default GustAppServiceOrderViewEmbedded;
