import {
  Datagrid,
  DateField,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
} from 'react-admin';
import { DurationField } from './DurationField';
import { PriceTypeField } from './PriceTypeField';
import { ServiceTypeField, serviceTypes } from './ServiceTypeField';
import { StatusField } from './StatusField';

const GuestAppServiceList = () => {
  return (
    <List
      filters={[
        <ReferenceInput alwaysOn source="tenant_id" reference="tenant_mini_server_view" label="Mini server">
          <SelectInput source="name" label="Mini server" />
        </ReferenceInput>,
        <SelectInput source="service_type" choices={serviceTypes} alwaysOn />,
      ]}
      sort={{ field: 'updated_at', order: 'DESC' }}
    >
      <Datagrid rowClick="show" bulkActionButtons={false} optimized>
        <TextField source="id" />
        <ReferenceField source="tenant_id" reference="tenant_mini_server_view" label="Mini-server" link="show">
          <TextField source="name" />
        </ReferenceField>
        <ServiceTypeField />
        <PriceTypeField />
        <DurationField />
        <NumberField source="price" label="price" />
        <TextField source="name" label="Name" />
        <StatusField />
        <DateField source="updated_at" showTime locales="de-AT" />
      </Datagrid>
    </List>
  );
};

export default GuestAppServiceList;
