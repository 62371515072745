import {
  Datagrid,
  DateField,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
} from 'react-admin';

import GustAppServiceOrderViewEmbedded from './GustAppServiceOrderViewEmbedded';

const statusChoices = [
  { id: 'succeeded', name: 'Succeeded' },
  { id: 'requires_payment_method', name: 'Requires payment method' },
  { id: 'pending', name: 'Pending' },
  { id: 'failed', name: 'Failed' },
  { id: 'finished', name: 'Finished' },
];

const GustAppServiceOrderList = () => {
  return (
    <List
      filters={[
        <ReferenceInput alwaysOn source="tenant_id" reference="tenant_mini_server_view" label="Mini server">
          <SelectInput source="name" label="Mini server" />
        </ReferenceInput>,
        <ReferenceInput alwaysOn source="guest_id" reference="guests" label="Guest">
          <SelectInput source="guest_name" label="Guest name" />
        </ReferenceInput>,
        <SelectInput source="payment_intent_status" choices={statusChoices} alwaysOn />,
      ]}
      sort={{ field: 'updated_at', order: 'DESC' }}
    >
      <Datagrid expand={<GustAppServiceOrderViewEmbedded />} rowClick="expand" optimized>
        <TextField source="id" />
        <ReferenceField source="tenant_id" reference="tenant_mini_server_view" label="Mini-server" link="show">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="guest_id" reference="guests" label="Guest" link="show">
          <TextField source="guest_name" />
        </ReferenceField>
        <TextField source="payment_intent_status" label="Status" />
        <NumberField source="amount" label="Amount" />
        <DateField source="updated_at" showTime locales="de-AT" />
      </Datagrid>
    </List>
  );
};

export default GustAppServiceOrderList;
