import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import OrderList from './OrderList';
import OrderShow from './OrderShow';

export default {
  list: OrderList,
  icon: AddShoppingCartIcon,
  show: OrderShow,
  recordRepresentation: (record: any) => `${record.id} ${record.date_modified_gmt}`,
};
