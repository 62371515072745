import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import SmoobuConfigList from './SmoobuConfigList';
import SmoobuConfigShow from './SmoobuConfigShow';

export default {
  list: SmoobuConfigList,
  icon: MapsHomeWorkIcon,
  show: SmoobuConfigShow,
  recordRepresentation: (record: any) => `${record.id}`,
};
