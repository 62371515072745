import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import GuestAppServiceList from './GuestAppServiceList';
import GuestAppServiceShow from './GuestAppServiceShow';

export default {
  list: GuestAppServiceList,
  icon: AddShoppingCartIcon,
  show: GuestAppServiceShow,
  recordRepresentation: (record: any) => `${record.id} ${record.name}`,
};
