import { useRecordContext } from 'react-admin';

export enum ServiceType {
  UncountedPart = 1,
  CountedPart = 2,
  AssignedParts = 3,
  PersonalService = 4,
  RoomService = 5,
  Other = 9999,
}
export const serviceTypes = [
  {
    id: ServiceType.UncountedPart,
    name: 'Uncounted part (Towels)',
  },
  {
    id: ServiceType.CountedPart,
    name: 'Counted part (bikes, sledge, parking space not assigned, coffee machine,...)',
  },
  {
    id: ServiceType.AssignedParts,
    name: 'Assigned parts (Garage/parking space Nr.X)',
  },
  {
    id: ServiceType.PersonalService,
    name: 'ServicePersonal (sauna, massage, breakfast, personal trainer)',
  },
  {
    id: ServiceType.RoomService,
    name: 'Room service (Conference room, Bike room, ...)',
  },
  {
    id: ServiceType.Other,
    name: 'Other',
  },
];

export const ServiceTypeField = (props: any) => {
  const record = useRecordContext(props);

  return record ? <span>{serviceTypes.find((type) => type.id === record.service_type)?.name}</span> : null;
};

ServiceTypeField.defaultProps = { label: 'Service type' };
