export enum Role_Enum {
  /** ADMIN */
  Master = 'master',
  /** SUPERUSER */
  Superuser = 'superuser',
  /** USER */
  User = 'user',
}

export enum Admin_Role_Type_Enum {
  /** Admin */
  Admin = 'admin',
  /** Admin Manager */
  AdminManager = 'admin_manager',
}
