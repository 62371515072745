import { useRecordContext } from 'react-admin';

export enum MiniServerStatus {
  Disconnected = 0,
  Connected = 1,
  Reconnecting = 2,
}

export const MiniServerStatusField = (props: any) => {
  const record = useRecordContext(props);
  const getLabelStatus = () => {
    switch (record.mini_server_status) {
      case MiniServerStatus.Disconnected:
        return 'Disconnected';
      case MiniServerStatus.Connected:
        return 'Connected';
      case MiniServerStatus.Reconnecting:
        return 'Reconnecting';
      default:
        return 'Unknown';
    }
  };
  return record?.mini_server_status !== null ? <span>{getLabelStatus()}</span> : null;
};

MiniServerStatusField.defaultProps = { label: 'Status' };
