import { DateField, ReferenceField, Show, SimpleShowLayout, TextField } from 'react-admin';
import { JsonDataViewField } from '../logs/JsonDataField';
import { MiniServerStatusField } from './MiniServerStatusField';

const MiniServerShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <ReferenceField source="user_id" reference="user" label="User">
          <TextField source="display_name" />
        </ReferenceField>
        <ReferenceField source="tenant_id" reference="tenant" label="Name">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="username" label="Username" />
        <TextField source="host" label="Host" />
        <MiniServerStatusField />
        <JsonDataViewField customFieldName="app3" label="App3" />
        <DateField source="synced_at" showTime locales="de-AT" />
        <DateField source="updated_at" showTime locales="de-AT" />
      </SimpleShowLayout>
    </Show>
  );
};

export default MiniServerShow;
