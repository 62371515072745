import { DateField, EmailField, Show, SimpleShowLayout, TextField } from 'react-admin';

const UserShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="display_name" />
        <EmailField source="email" />
        <TextField source="language" />
        <DateField source="updated_at" showTime locales="de-AT" />
        <DateField source="created_at" showTime locales="de-AT" />
      </SimpleShowLayout>
    </Show>
  );
};

export default UserShow;
