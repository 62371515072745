import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  ReferenceField,
  ReferenceInput,
  ReferenceOneField,
  SelectInput,
  TextField,
} from 'react-admin';
import { TenantExtensionField } from '../user-extension/TenantExtensionField';
import CaldavViewEmbedded from './CaldavViewEmbedded';

const calendarTypeChoices = [
  { id: 'google', name: 'Google' },
  { id: 'apple', name: 'Apple' },
];

const CaldavList = () => {
  return (
    <List
      sort={{ field: 'updated_at', order: 'DESC' }}
      filters={[
        <ReferenceInput alwaysOn source="user_id" reference="user" label="User">
          <SelectInput source="display_name" />
        </ReferenceInput>,
        <SelectInput alwaysOn source="type" choices={calendarTypeChoices} />,
      ]}
    >
      <Datagrid expand={<CaldavViewEmbedded />} rowClick="expand" optimized>
        <TextField source="id" />

        <ReferenceField source="tenant_id" reference="tenant_mini_server_view" label="Mini-server" link="show">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceOneField
          target="calendar_id"
          reference="tenant_user_extension"
          label="Subscription - Extension"
          source="id"
        >
          <TenantExtensionField />
        </ReferenceOneField>

        <TextField source="type" label="Type" />
        {/* <TextField
          source="calendar_id"
          label="Calendar id"
          style={{ display: 'inline-block', maxWidth: '20em', overflow: 'hidden', textOverflow: 'ellipsis' }}
        /> */}

        <TextField source="name" label="Name" />
        <FunctionField
          label="Color"
          render={(record: any) => <div style={{ backgroundColor: record.color, width: '20px', height: '20px' }} />}
          style={{ display: 'inline-block', maxWidth: '20em', overflow: 'hidden', textOverflow: 'ellipsis' }}
        />
        <TextField source="sync_interval" label="Sync interval (Hour)" />
        <DateField source="synced_at" showTime locales="de-AT" />
        <DateField source="updated_at" showTime locales="de-AT" />
      </Datagrid>
    </List>
  );
};

export default CaldavList;
