import PeopleIcon from '@mui/icons-material/People';
import { ReactElement } from 'react';
import { Datagrid, DateField, List, SearchInput, TextField } from 'react-admin';
import { FullNameField } from './FullNameField';

export const UserIcon = PeopleIcon;

const getUserFilters = (): ReactElement[] => {
  return [<SearchInput source="display_name,email" alwaysOn />];
};

const UserList = () => {
  return (
    <List filters={getUserFilters()} sort={{ field: 'updated_at', order: 'ASC' }}>
      <Datagrid rowClick="show" optimized bulkActionButtons={false}>
        <TextField source="id" />
        <FullNameField />
        <TextField source="email" />
        <TextField source="language" />
        <DateField source="updated_at" showTime locales="de-AT" />
      </Datagrid>
    </List>
  );
};

export default UserList;
