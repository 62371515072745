import englishMessages from 'ra-language-english';

export const messages = {
  simple: {
    action: {
      close: 'Close',
      resetViews: 'Reset views',
    },
    'create-post': 'New post',
  },
  ...englishMessages,
  resources: {
    users: {
      name: 'User |||| Users',
      fields: {
        name: 'Name',
        role: 'Role',
      },
    },
    guest: {
      name: 'Guests',
      fields: {
        create_key: 'Create Key',
      },
      actions: {
        create_key: 'Add',
      },
    },
    reservation: {
      name: 'Reservation',
      fields: {
        create_key: 'Create Key',
      },
      actions: {
        add_key: 'Add',
      },
    },
    key_view: {
      name: 'Key Manager',
      resend_invitation_mail: 'Resend Invitation Mail',
      fields: {
        number: 'Room',
        is_in_loxone: 'Loxone',
        confirmation_number: 'Confirmation Number',
        full_name: 'Guest Name',
      },
    },
    key: {
      edit: {
        title: 'Edit Groups',
      },
    },
    room: {
      list: {
        number: 'Room Number',
      },
      form: {},
      fields: {
        number: 'Room Number',
      },
      edit: {
        title: 'Setting "%{title}"',
      },
      action: {
        save_and_edit: 'Save and Edit',
        save_and_add: 'Save and Add',
        save_and_show: 'Save and Show',
        save_with_average_note: 'Save with Note',
      },
    },
    setting: {
      name: 'Setting',
      list: {
        search: 'Search',
      },
      form: {},
      edit: {
        title: 'Setting "%{title}"',
      },
      action: {
        save_and_edit: 'Save and Edit',
        save_and_add: 'Save and Add',
        save_and_show: 'Save and Show',
        save_with_average_note: 'Save with Note',
        login_snt: 'Login SNT',
      },
    },
    sync: {
      list: {
        note_empty: 'Empty Note',
      },
      form: {
        summary: 'Summary',
        body: 'Body',
        miscellaneous: 'Miscellaneous',
        comments: 'Comments',
      },
      edit: {
        title: 'Post "%{title}"',
      },
      action: {
        save_and_edit: 'Save and Edit',
        save_and_add: 'Save and Add',
        save_and_show: 'Save and Show',
        save_with_average_note: 'Save with Note',
      },
    },
  },
  user: {
    list: {
      search: 'Search',
    },
    form: {
      summary: 'Summary',
      security: 'Security',
    },
    action: {
      save_and_add: 'Save and Add',
      save_and_show: 'Save and Show',
    },
  },
};

export default messages;
