import KeyboardIcon from '@mui/icons-material/Keyboard';
import MiniServerList from './MiniServerList';
import MiniServerShow from './MiniServerShow';

export default {
  list: MiniServerList,
  icon: KeyboardIcon,
  show: MiniServerShow,
  recordRepresentation: (record: any) => `${record.id}`,
};
