import { Link, useRecordContext } from 'react-admin';

export const JsonDataUserExtensionField = (props: any) => {
  const record = useRecordContext(props);
  const data = record['json_data'];

  if (data?.tenant_id && data?.mini_server_id) {
    return <Link to={`/tenant_mini_server_view/${data.mini_server_id}/show`}>Mini-server: {data.mini_server_id}</Link>;
  }

  if (data?.sb_config_id) {
    return <Link to={`/sb_config/${data.sb_config_id}/show`}>Smoobu config: {data.sb_config_id}</Link>;
  }

  if (data?.calendar_id) {
    return <Link to={`/caldav/${data.calendar_id}/show`}>Calendar: {data.calendar_id}</Link>;
  }

  return <></>;
};

JsonDataUserExtensionField.defaultProps = { label: 'Used for' };
