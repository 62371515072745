import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  ReferenceInput,
  ReferenceOneField,
  SelectInput,
  TextField,
} from 'react-admin';
import { TenantExtensionField } from '../user-extension/TenantExtensionField';
import SmoobuConfigViewEmbedded from './SmoobuConfigViewEmbedded';

const SmoobuConfigList = () => {
  return (
    <List
      sort={{ field: 'updated_at', order: 'DESC' }}
      filters={[
        <ReferenceInput alwaysOn source="tenant_id" reference="tenant_mini_server_view" label="Mini server">
          <SelectInput source="name" label="Mini server" />
        </ReferenceInput>,
      ]}
    >
      <Datagrid expand={<SmoobuConfigViewEmbedded />} rowClick="show" optimized bulkActionButtons={false}>
        <TextField source="id" />

        <ReferenceField source="tenant_id" reference="tenant_mini_server_view" label="Mini-server" link="show">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceOneField
          target="sb_config_id"
          reference="tenant_user_extension"
          label="Subscription - Extension"
          source="id"
        >
          <TenantExtensionField />
        </ReferenceOneField>
        <TextField
          source="api_key"
          label="Api key"
          style={{ display: 'inline-block', maxWidth: '20em', overflow: 'hidden', textOverflow: 'ellipsis' }}
        />

        <TextField source="default_check_in" label="Default check in" />
        <TextField source="default_check_out" label="Default check out" />
        <DateField source="synced_at" showTime locales="de-AT" />
        <DateField source="updated_at" showTime locales="de-AT" />
      </Datagrid>
    </List>
  );
};

export default SmoobuConfigList;
