import { DateField, NumberField, ReferenceField, Show, SimpleShowLayout, TextField } from 'react-admin';
import { JsonDataViewField } from '../logs/JsonDataField';

const GuestAppServiceOrderShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <ReferenceField source="tenant_id" reference="tenant_mini_server_view" label="Mini-server" link="show">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="guest_id" reference="guests" label="Guest" link="show">
          <TextField source="guest_name" />
        </ReferenceField>
        <TextField source="payment_intent_status" label="Status" />
        <NumberField source="amount" label="Amount" />
        <JsonDataViewField customFieldName="order_data" label="Order data" />
        <DateField source="updated_at" showTime locales="de-AT" />
      </SimpleShowLayout>
    </Show>
  );
};

export default GuestAppServiceOrderShow;
