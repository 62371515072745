import { useRecordContext } from 'react-admin';
import { PriceType } from './PriceTypeField';

export const durationByHours = [
  {
    id: 1,
    name: '1h',
  },
  {
    id: 2,
    name: '2h',
  },
  {
    id: 3,
    name: '3h',
  },
  {
    id: 4,
    name: '4h',
  },
  {
    id: 5,
    name: '5h',
  },
  {
    id: 6,
    name: '6h',
  },
  {
    id: 7,
    name: '7h',
  },
  {
    id: 8,
    name: '8h',
  },
  {
    id: 9,
    name: '9h',
  },
  {
    id: 10,
    name: '10h',
  },
  {
    id: 11,
    name: '11h',
  },
  {
    id: 12,
    name: '12h',
  },
  {
    id: 13,
    name: '13h',
  },
  {
    id: 14,
    name: '14h',
  },
  {
    id: 15,
    name: '15h',
  },
  {
    id: 16,
    name: '16h',
  },
  {
    id: 17,
    name: '17h',
  },
  {
    id: 18,
    name: '18h',
  },
  {
    id: 19,
    name: '19h',
  },
  {
    id: 20,
    name: '20h',
  },
  {
    id: 21,
    name: '21h',
  },
  {
    id: 22,
    name: '22h',
  },
  {
    id: 23,
    name: '23h',
  },
  {
    id: 24,
    name: '24h',
  },
];

export const durationByDays = [
  {
    id: 1,
    name: '1 day',
  },
  {
    id: 2,
    name: '2 days',
  },
  {
    id: 3,
    name: '3 days',
  },
  {
    id: 4,
    name: '4 days',
  },
  {
    id: 5,
    name: '5 days',
  },
  {
    id: 6,
    name: '6 days',
  },
  {
    id: 7,
    name: '7 days',
  },
];

export const durationByNights = [
  {
    id: 1,
    name: '1 night',
  },
  {
    id: 2,
    name: '2 nights',
  },
  {
    id: 3,
    name: '3 nights',
  },
  {
    id: 4,
    name: '4 nights',
  },
  {
    id: 5,
    name: '5 nights',
  },
  {
    id: 6,
    name: '6 nights',
  },
  {
    id: 7,
    name: '7 nights',
  },
];

export const durationByStay = [
  {
    id: 1,
    name: '1 stay',
  },
  {
    id: 2,
    name: '2 stays',
  },
  {
    id: 3,
    name: '3 stays',
  },
  {
    id: 4,
    name: '4 stays',
  },
  {
    id: 5,
    name: '5 stays',
  },
  {
    id: 6,
    name: '6 stays',
  },
  {
    id: 7,
    name: '7 stays',
  },
];

export const DurationField = (props: any) => {
  const record = useRecordContext(props);

  const priceType = record.price_type;

  let durationOptions: any = [];
  switch (priceType) {
    case PriceType.PerHour:
      durationOptions = durationByHours;
      break;
    case PriceType.PerDay:
      durationOptions = durationByDays;
      break;
    case PriceType.PerNight:
      durationOptions = durationByNights;
      break;
    case PriceType.PerStay:
      durationOptions = durationByStay;
      break;
    default:
      durationOptions = [];
      break;
  }

  return record ? <span>{durationOptions.find((item: any) => item.id === record.duration)?.name}</span> : null;
};

DurationField.defaultProps = { label: 'Duration' };
