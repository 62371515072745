import PropTypes from 'prop-types';
import { DateField, FunctionField, Identifier, ReferenceField, Show, SimpleShowLayout, TextField } from 'react-admin';
import { JsonDataViewField } from '../logs/JsonDataField';
import { CreatedEventStatus } from './CreatedEventList';

const CreatedEventViewEmbedded = ({ id }: { id?: Identifier }) => {
  return (
    <Show id={id} queryOptions={{ refetchInterval: 5000 }}>
      <SimpleShowLayout>
        <TextField source="id" />
        <ReferenceField source="tenant_id" link="show" reference="tenant_mini_server_view" label="Mini-server">
          <TextField source="name" />
        </ReferenceField>
        <DateField source="start_at" showTime locales="de-AT" />
        <DateField source="end_at" showTime locales="de-AT" />
        <FunctionField label="Start status" render={(record: any) => CreatedEventStatus[record.start_status]} />
        <FunctionField label="End status" render={(record: any) => CreatedEventStatus[record.end_status]} />
        <ReferenceField source="synced_event_id" reference="synced_event">
          <TextField source="title" />
        </ReferenceField>
        <JsonDataViewField />
      </SimpleShowLayout>
    </Show>
  );
};

CreatedEventViewEmbedded.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
  id: PropTypes.string,
};

export default CreatedEventViewEmbedded;
