import PropTypes from 'prop-types';
import {
  Datagrid,
  DateField,
  Identifier,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  ReferenceOneField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import { TenantExtensionField } from '../user-extension/TenantExtensionField';

const SmoobuConfigShow = ({ id }: { id?: Identifier }) => {
  return (
    <Show id={id} queryOptions={{ refetchInterval: 5000 }}>
      <SimpleShowLayout>
        <TextField source="id" />

        <ReferenceField source="tenant_id" reference="tenant_mini_server_view" label="Mini-server" link="show">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceOneField
          target="sb_config_id"
          reference="tenant_user_extension"
          label="Subscription - Extension"
          source="id"
        >
          <TenantExtensionField />
        </ReferenceOneField>
        <TextField source="api_key" label="Api key" />
        <TextField source="day_before_check_arrival" label="Day before check arrival" />
        <TextField source="extend_checkout_time_by_xx_min" label="Extend checkout time by xx min" />
        <TextField source="count_of_pin" label="Count of pin" />
        <TextField source="show_pin_in_sb" label="Show pin in sb" />
        <TextField source="default_check_in" label="Default check in" />
        <TextField source="default_check_out" label="Default check out" />
        <DateField source="synced_at" showTime locales="de-AT" />
        <DateField source="updated_at" showTime locales="de-AT" />

        <ReferenceManyField
          perPage={10}
          pagination={<Pagination />}
          reference="sb_log"
          source="tenant_id"
          target="tenant_id"
          label="Smoobu logs"
        >
          <Datagrid bulkActionButtons={false}>
            <TextField source="id" />
            <TextField source="title" />
            <DateField source="created_at" showTime locales="de-AT" />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};

SmoobuConfigShow.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
  id: PropTypes.string,
};

export default SmoobuConfigShow;
