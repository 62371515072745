import { Datagrid, DateField, List, TextField } from 'react-admin';

import SettingEditEmbedded from './SettingEditEmbedded';

const SettingList = () => {
  return (
    <List>
      <Datagrid expand={<SettingEditEmbedded />} rowClick="expand" optimized>
        <TextField source="id" />
        <TextField source="label" />
        <TextField source="key" />
        <TextField source="value" />
        <DateField source="updated_at" showTime locales="de-AT" />
      </Datagrid>
    </List>
  );
};

export default SettingList;
