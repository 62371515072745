import { setAuthorizationHeader } from 'src/app';

type Credential = { email: string; password: string };
const baseApiUrl = process.env.REACT_APP_BASE_API_URL || '';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const authProvider = {
  login: async (props: Credential) => {
    return fetch(baseApiUrl, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        operationName: 'AdminLogin',
        variables: null,
        query: `mutation AdminLogin {\n  login_admin(params: {email: "${props.email}", password: "${props.password}"}) {\n    error\n    token\n    user_id\n  }\n}\n`,
      }),
    })
      .then((response: Response) => {
        if (response.status < 200 || response.status >= 300) throw new Error(response.statusText);
        return response.json();
      })
      .then(async (resBody) => {
        const { data } = resBody;
        if (data.login_admin.error) throw new Error(data.login_admin.error);
        setAuthorizationHeader(data.login_admin.token);
        localStorage.setItem('auth', JSON.stringify(data.login_admin));
      })
      .catch((error) => {
        throw new Error(error.message ?? 'Network error');
      });
  },

  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem('auth');
    return Promise.resolve();
  },

  // called when the API returns an error
  checkError: (status: number) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('auth');
      return Promise.reject();
    }
    return Promise.resolve();
  },

  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem('auth') ? Promise.resolve() : Promise.reject();
  },

  getIdentity: () => {
    try {
      const authParams = localStorage?.getItem('auth') || '';
      if (authParams == '') return Promise.reject(Error('No auth params'));
      const { user_id: id, token } = JSON.parse(authParams) as { user_id: string; token: string };
      return Promise.resolve({ id, token });
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    try {
      const authParams = localStorage?.getItem('auth') || '';
      if (authParams == '') return Promise.reject(Error('No auth params'));
      console.log(JSON.parse(authParams));
      const { user_id: id, token } = JSON.parse(authParams) as { user_id: string; token: string };

      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(''),
      );
      const payload = JSON.parse(jsonPayload);
      const role = payload['https://hasura.io/jwt/claims']['x-hasura-default-role'];

      return Promise.resolve(role);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
