/* eslint react/jsx-key: off */
import PropTypes from 'prop-types';
import { DateField, Identifier, ReferenceField, Show, SimpleShowLayout, TextField } from 'react-admin';
import { JsonDataUserExtensionField } from './JsonDataUserExtensionField';
import { NormalStatusField } from './NormalStatusField';

const UserExtensionViewEmbedded = ({ id }: { id?: Identifier }) => {
  return (
    <Show id={id} queryOptions={{ refetchInterval: 5000 }}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="wordpress_customer_extension_id" />
        <TextField source="is_used" label="Is used?" />
        <ReferenceField source="user_id" reference="user" link="show">
          <TextField source="display_name" />
        </ReferenceField>
        <ReferenceField source="subscription_id" reference="subscriptions" link="show">
          <TextField source="product_name" />
        </ReferenceField>
        <NormalStatusField />
        <DateField source="start_date" showTime locales="de-AT" />
        <DateField source="next_payment_date" showTime locales="de-AT" />
        <DateField source="expired_date" showTime locales="de-AT" />
        <DateField source="cancelled_date" showTime locales="de-AT" />
        <DateField source="end_date" showTime locales="de-AT" />
        <DateField source="updated_at" showTime locales="de-AT" />
        <JsonDataUserExtensionField customFieldName="json_data" />
      </SimpleShowLayout>
    </Show>
  );
};

UserExtensionViewEmbedded.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
  id: PropTypes.string,
};

export default UserExtensionViewEmbedded;
