import { Datagrid, List, TextField } from 'react-admin';

import ProductViewEmbedded from './ProductViewEmbedded';

const ProductList = () => {
  return (
    <List sort={{ field: 'id', order: 'DESC' }}>
      <Datagrid bulkActionButtons={false} expand={<ProductViewEmbedded />} rowClick="expand" optimized>
        <TextField source="id" />
        <TextField source="name" label="Name" />
        <TextField source="slug" label="Slug" />
      </Datagrid>
    </List>
  );
};

export default ProductList;
