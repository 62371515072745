import { useRecordContext } from 'react-admin';
import ReactJson from 'react-json-view';

export const JsonDataViewField = (props: any) => {
  const record = useRecordContext(props);
  let fieldName = 'json_data';
  if (props.customFieldName) {
    fieldName = props.customFieldName;
  }
  if (record[fieldName]) {
    return record ? <ReactJson src={record[fieldName]} /> : null;
  } else {
    return <></>;
  }
};

JsonDataViewField.defaultProps = { label: 'Json data' };
