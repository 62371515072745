import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  ReferenceInput,
  ReferenceOneField,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';

import { TenantExtensionField } from '../user-extension/TenantExtensionField';

const MiniServerList = () => {
  return (
    <List
      filters={[
        <TextInput source="username,host" alwaysOn label="Keyword" />,
        <ReferenceInput source="user_id" reference="user" alwaysOn>
          <SelectInput source="display_name" />
        </ReferenceInput>,
      ]}
      sort={{ field: 'updated_at', order: 'DESC' }}
    >
      <Datagrid rowClick="show" optimized bulkActionButtons={false}>
        <TextField source="id" />
        <ReferenceField source="user_id" reference="user" label="User" link="show">
          <TextField source="display_name" />
        </ReferenceField>
        <TextField source="name" label="Name" />
        {/* <ReferenceField source="tenant_id" reference="tenant" label="Name">
          <TextField source="name" />
        </ReferenceField> */}
        <ReferenceOneField
          target="mini_server_id"
          reference="tenant_user_extension"
          label="Subscription - Extension"
          source="id"
        >
          <TenantExtensionField />
        </ReferenceOneField>
        <TextField source="username" label="Username" />
        <TextField source="host" label="Host" />
        <DateField source="synced_at" showTime locales="de-AT" />
        <DateField source="updated_at" showTime locales="de-AT" />
      </Datagrid>
    </List>
  );
};

export default MiniServerList;
