import PeopleIcon from '@mui/icons-material/People';
import GuestList from './GuestList';
import GuestShow from './GuestShow';

export default {
  list: GuestList,
  show: GuestShow,
  icon: PeopleIcon,
  recordRepresentation: (record: any) => `${record.guest_name}`,
};
