import PropTypes from 'prop-types';
import { Identifier, ReferenceField, Show, SimpleShowLayout, TextField } from 'react-admin';
import { JsonDataViewField } from '../logs/JsonDataField';

const TaskViewEmbedded = ({ id }: { id?: Identifier }) => {
  return (
    <Show id={id} queryOptions={{ refetchInterval: 5000 }}>
      <SimpleShowLayout>
        <TextField source="id" />
        <ReferenceField link="show" source="tenant_id" reference="tenant" label="Mini-server">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="name" />
        <TextField source="keyword" />
        <TextField source="notify_error" />
        <JsonDataViewField />
      </SimpleShowLayout>
    </Show>
  );
};

TaskViewEmbedded.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
  id: PropTypes.string,
};

export default TaskViewEmbedded;
