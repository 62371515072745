import { Datagrid, DateField, List, ReferenceField, ReferenceInput, SelectInput, TextField } from 'react-admin';

import TaskViewEmbedded from './TaskViewEmbedded';

const TaskList = () => {
  return (
    <List
      filters={[
        <ReferenceInput alwaysOn source="tenant_id" reference="tenant_mini_server_view" label="Mini server">
          <SelectInput source="name" label="Mini server" />
        </ReferenceInput>,
      ]}
      sort={{ field: 'updated_at', order: 'DESC' }}
    >
      <Datagrid expand={<TaskViewEmbedded />} rowClick="expand" optimized bulkActionButtons={false}>
        <TextField source="id" />
        <ReferenceField source="tenant_id" reference="tenant_mini_server_view" link="show" label="Mini-server">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="name" />
        <TextField source="keyword" />
        <TextField source="notify_error" />
        <DateField source="created_at" showTime locales="de-AT" />
      </Datagrid>
    </List>
  );
};

export default TaskList;
