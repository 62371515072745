import { Datagrid, DateField, List, ReferenceField, ReferenceInput, SelectInput, TextField } from 'react-admin';

import { NormalStatusField } from './NormalStatusField';
import UserExtensionViewEmbedded from './UserExtensionViewEmbedded';

const UserExtensionList = () => {
  return (
    <List
      sort={{ field: 'updated_at', order: 'DESC' }}
      filters={[
        <ReferenceInput alwaysOn source="user_id" reference="user" label="User">
          <SelectInput source="display_name" />
        </ReferenceInput>,
        <ReferenceInput alwaysOn source="subscription_id" reference="subscriptions" label="Subscription">
          <SelectInput source="product_name" />
        </ReferenceInput>,
      ]}
    >
      <Datagrid expand={<UserExtensionViewEmbedded />} rowClick="expand" optimized bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="wordpress_customer_extension_id" />
        <TextField source="is_used" label="Is used?" />
        <ReferenceField source="user_id" reference="user" link="show">
          <TextField source="display_name" />
        </ReferenceField>
        <ReferenceField source="subscription_id" reference="subscriptions" link="show">
          <TextField source="product_name" />
        </ReferenceField>
        <ReferenceField source="order_id" reference="order" link="show">
          <TextField source="id" />
        </ReferenceField>
        <NormalStatusField />
        <DateField source="start_date" showTime locales="de-AT" />
        <DateField source="end_date" showTime locales="de-AT" />
        <DateField source="updated_at" showTime locales="de-AT" />
      </Datagrid>
    </List>
  );
};

export default UserExtensionList;
