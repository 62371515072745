/* eslint react/jsx-key: off */
import { ReferenceField, Show, SimpleShowLayout, TextField } from 'react-admin';
import { JsonDataViewField } from '../logs/JsonDataField';

const OrderShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <ReferenceField source="customer_id" reference="user" label="User">
          <TextField source="display_name" />
        </ReferenceField>
        <TextField source="status" label="Status" />
        <TextField source="customer_note" label="Customer note" />

        <JsonDataViewField customFieldName="line_items" label="Line items" />
        <JsonDataViewField customFieldName="meta_data" label="Meta data" />
      </SimpleShowLayout>
    </Show>
  );
};

export default OrderShow;
