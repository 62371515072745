import { Datagrid, DateField, FunctionField, List, ReferenceField, TextField } from 'react-admin';

import CreatedEventViewEmbedded from './CreatedEventViewEmbedded';

export enum CreatedEventStatus {
  Pending = 0,
  Processing = 1,
  Success = 2,
  Failed = 3,
}
const CreatedEventList = () => {
  return (
    <List sort={{ field: 'updated_at', order: 'DESC' }}>
      <Datagrid expand={<CreatedEventViewEmbedded />} rowClick="expand" optimized bulkActionButtons={false}>
        <TextField source="id" />
        <ReferenceField source="tenant_id" reference="tenant_mini_server_view" label="Mini-server" link="show">
          <TextField source="name" />
        </ReferenceField>

        <DateField source="start_at" showTime locales="de-AT" />
        <DateField source="end_at" showTime locales="de-AT" />
        <FunctionField label="Start status" render={(record: any) => CreatedEventStatus[record.start_status]} />
        <FunctionField label="End status" render={(record: any) => CreatedEventStatus[record.end_status]} />
        <ReferenceField source="synced_event_id" reference="synced_event">
          <TextField source="title" />
        </ReferenceField>

        <DateField source="created_at" showTime locales="de-AT" />
      </Datagrid>
    </List>
  );
};

export default CreatedEventList;
