/* eslint react/jsx-key: off */
import PropTypes from 'prop-types';
import { Identifier, ReferenceField, Show, SimpleShowLayout, TextField } from 'react-admin';
import { JsonDataViewField } from '../logs/JsonDataField';

const OrderViewEmbedded = ({ id }: { id?: Identifier }) => {
  return (
    <Show id={id} queryOptions={{ refetchInterval: 5000 }}>
      <SimpleShowLayout>
        <TextField source="id" />
        <ReferenceField source="customer_id" reference="user" label="User">
          <TextField source="display_name" />
        </ReferenceField>
        <TextField source="status" label="Status" />
        <TextField source="customer_note" label="Customer note" />

        <JsonDataViewField customFieldName="line_items" label="Line items" />
        <JsonDataViewField customFieldName="meta_data" label="Meta data" />
      </SimpleShowLayout>
    </Show>
  );
};

OrderViewEmbedded.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
  id: PropTypes.string,
};

export default OrderViewEmbedded;
