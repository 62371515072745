import { createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Box, CircularProgress } from '@mui/material';
import { createBrowserHistory as createHistory } from 'history';
import buildHasuraProvider from 'ra-data-hasura';
import React from 'react';
import { Admin, DataProvider, Resource } from 'react-admin';
import { LayoutWrapper } from './components/layout-wrapper';
import { Login } from './pages';
import caldav from './pages/caldav';
import createdEvent from './pages/created-event';
import extensions from './pages/extensions';
import guestAppServiceOrders from './pages/guest-app-service-orders';
import guestAppServices from './pages/guest-app-services';
import guests from './pages/guests';
import logs from './pages/logs';
import miniserver from './pages/miniserver';
import order from './pages/order';
import products from './pages/products';
import settings from './pages/settings';
import smoobuConfig from './pages/smoobu-config';
import subscriptions from './pages/subscriptions';
import task from './pages/task';
import userExtension from './pages/user-extension';
import users from './pages/users';
import { authProvider } from './providers/auth.provider';
import i18nProvider from './providers/i18n.provider';
import { lightTheme } from './services/theme';
const baseApiUrl = process.env.REACT_APP_BASE_API_URL || '';

const httpLink = createHttpLink({
  uri: baseApiUrl,
});

let headersAuth = {};
export const setAuthorizationHeader = (token: string) => {
  headersAuth = {
    authorization: `Bearer ${token}`,
  };
};

const App: React.FC = () => {
  const history = createHistory();
  const [dataProvider, setDataProvider] = React.useState<DataProvider | null>(null);
  const authLinkBuilder = () => {
    return setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          ...headersAuth,
        },
      };
    });
  };
  React.useEffect(() => {
    const buildDataProvider = async () => {
      let token = '';
      try {
        const auth = await authProvider.getIdentity();
        if (auth) {
          token = auth.token;
        }
      } catch (e) {
        token = '';
      }
      setAuthorizationHeader(token);
      const authLink = authLinkBuilder();
      const dataProviderHasura = await buildHasuraProvider({
        clientOptions: {
          link: authLink.concat(httpLink) as any,
        },
      });
      setDataProvider(() => dataProviderHasura);
    };
    buildDataProvider();
  }, []);

  if (!dataProvider)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress color="secondary" />
        <CircularProgress />
      </Box>
    );

  return (
    <Admin
      requireAuth={true}
      theme={lightTheme}
      layout={LayoutWrapper}
      loginPage={Login}
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      history={history}
    >
      <Resource name="log" {...logs} />
      <Resource name="user" {...users} />
      <Resource name="tenant_mini_server_view" {...miniserver} recordRepresentation="name" />
      <Resource name="caldav" {...caldav} />
      <Resource name="task" {...task} />
      <Resource name="created_event" {...createdEvent} />
      <Resource name="subscriptions" {...subscriptions} />
      <Resource name="order" {...order} />
      <Resource name="products" {...products} />
      <Resource name="user_extension" {...userExtension} />
      <Resource name="sb_config" {...smoobuConfig} />
      <Resource name="guests" {...guests} />
      <Resource name="guest_app_service_orders" {...guestAppServiceOrders} />
      <Resource name="guest_app_services" {...guestAppServices} />
      <Resource name="system_settings" {...settings} />
      <Resource name="extensions" {...extensions} />
    </Admin>
  );
};

export default App;
