import { Chip } from '@mui/material';
import { useRecordContext } from 'react-admin';

export const NormalStatusField = (props: any) => {
  const record = useRecordContext(props);
  return record ? (
    <span>
      {record.status === 0 ? (
        <Chip label="Inactive" size="small" />
      ) : (
        <Chip label="Active" size="small" color="success" />
      )}
    </span>
  ) : null;
};

NormalStatusField.defaultProps = { label: 'Status' };
