import { DateField, EmailField, ReferenceField, Show, SimpleShowLayout, TextField } from 'react-admin';

const GuestShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="sb_guest_id" />
        <DateField source="birthday" showTime locales="de-AT" />
        <TextField source="guest_name" />
        <TextField source="code" />
        <EmailField source="email" />
        <TextField source="phone" />
        <TextField source="language" />
        <ReferenceField source="tenant_id" reference="tenant_mini_server_view" label="Mini-server" link="show">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="loxone_user_uuid" />
        <DateField source="updated_at" showTime locales="de-AT" />
      </SimpleShowLayout>
    </Show>
  );
};

export default GuestShow;
