import { Datagrid, DateField, List, ReferenceInput, ReferenceOneField, SelectInput, TextField } from 'react-admin';

import OrderViewEmbedded from './OrderViewEmbedded';

const OrderList = () => {
  return (
    <List
      filters={[
        <ReferenceInput alwaysOn source="user_id" reference="user" label="User">
          <SelectInput source="display_name" />
        </ReferenceInput>,
      ]}
      sort={{ field: 'date_modified_gmt', order: 'DESC' }}
    >
      <Datagrid expand={<OrderViewEmbedded />} rowClick="expand" optimized bulkActionButtons={false}>
        <TextField source="id" />
        <ReferenceOneField source="customer_id" reference="user" target="id" label="User" link="show">
          <TextField source="display_name" />
        </ReferenceOneField>
        <TextField source="status" label="Status" />
        <DateField source="date_modified_gmt" showTime locales="de-AT" />
      </Datagrid>
    </List>
  );
};

export default OrderList;
