import { useRecordContext } from 'react-admin';

export enum PriceType {
  PerHour = 1,
  PerDay = 2,
  PerNight = 3,
  PerStay = 4,
  PerMinute = 5,
}

export const servicePriceTypes = [
  {
    id: PriceType.PerHour,
    name: 'Per hour',
  },
  {
    id: PriceType.PerDay,
    name: 'Per day',
  },
  {
    id: PriceType.PerNight,
    name: 'Per night',
  },
  {
    id: PriceType.PerStay,
    name: 'Per stay',
  },
  {
    id: PriceType.PerMinute,
    name: 'Per minute',
  },
];

export const PriceTypeField = (props: any) => {
  const record = useRecordContext(props);

  return record ? <span>{servicePriceTypes.find((type) => type.id === record.price_type)?.name}</span> : null;
};

PriceTypeField.defaultProps = { label: 'Price type' };
