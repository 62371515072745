import { useRecordContext } from 'react-admin';

export enum PrivateService {
  False = 0,
  True = 1,
}

export const StatusField = (props: any) => {
  const record = useRecordContext(props);

  return record ? <span>{record.status === PrivateService.True ? 'Private' : 'Public'}</span> : null;
};

StatusField.defaultProps = { label: 'Status' };
