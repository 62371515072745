import { DateField, NumberField, ReferenceField, Show, SimpleShowLayout, TextField } from 'react-admin';
import { JsonDataViewField } from '../logs/JsonDataField';
import { DurationField } from './DurationField';
import { PriceTypeField } from './PriceTypeField';
import { ServiceTypeField } from './ServiceTypeField';
import { StatusField } from './StatusField';

const GuestAppServiceShow = () => {
  return (
    <Show>
      <SimpleShowLayout>
        <TextField source="id" />
        <ReferenceField source="tenant_id" reference="tenant_mini_server_view" label="Mini-server" link="show">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="description" label="Description" />
        <ServiceTypeField />
        <PriceTypeField />
        <DurationField />
        <NumberField source="price" label="price" />
        <TextField source="name" label="Name" />
        <StatusField />
        <NumberField source="slot_min" />
        <NumberField source="slot_max" />
        <NumberField source="slot_available" />
        <NumberField source="start_time_available" />
        <NumberField source="end_time_available" />
        <TextField source="is_free_service" />
        <TextField source="is_private_booking" />
        <JsonDataViewField customFieldName="time_slots" label="Time slots" />
        <JsonDataViewField customFieldName="disabled_time_slots" label="Disable Time slots" />
        <DateField source="updated_at" showTime locales="de-AT" />
      </SimpleShowLayout>
    </Show>
  );
};

export default GuestAppServiceShow;
