import { Box, Chip } from '@mui/material';
import { useRecordContext } from 'react-admin';
import { useNavigate } from 'react-router-dom';
export enum Extensions_Enum {
  /** Calendar */
  Calendar = 'calendar',
  /** Charts */
  Charts = 'charts',
  /** Energy Tracking */
  EnergyTracking = 'energy_tracking',
  /** MiniServer */
  MiniServer = 'mini_server',
  /** My Smartkey Lite */
  MySmartkeyLite = 'my_smartkey_lite',
  /** Smoobu */
  Smoobu = 'smoobu',
  /** Time Tracking */
  TimeTracking = 'time_tracking',
}

export enum UserExtensionStatus {
  Active = 1,
  Inactive = 0,
}

const getExtensionLabel = (extension: Extensions_Enum) => {
  switch (extension) {
    case Extensions_Enum.Calendar:
      return 'Calendar';
    case Extensions_Enum.Charts:
      return 'Charts';
    case Extensions_Enum.EnergyTracking:
      return 'Energy Tracking';
    case Extensions_Enum.MiniServer:
      return 'MiniServer';
    case Extensions_Enum.MySmartkeyLite:
      return 'My Smartkey Lite';
    case Extensions_Enum.Smoobu:
      return 'Smoobu';
    case Extensions_Enum.TimeTracking:
      return 'Time Tracking';
    default:
      return extension;
  }
};

export const TenantExtensionField = (props: any) => {
  const record = useRecordContext(props);
  const navigate = useNavigate();

  console.log({ record });
  return record ? (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
      <Chip
        label={'Subscription: ' + record.subscription_id}
        color="primary"
        size="small"
        onClick={() => {
          if (!record.subscription_id) return;
          navigate(`/subscriptions/${record.subscription_id}/show`);
        }}
      />
      <Box sx={{ width: 8 }} />
      <Chip
        label={getExtensionLabel(record.extension)}
        color="secondary"
        size="small"
        onClick={() => {
          if (!record.user_extension_id) return;
          navigate(`/user_extension/${record.user_extension_id}/show`);
        }}
      />
    </Box>
  ) : null;
};

TenantExtensionField.defaultProps = { label: 'Subscription / Extension' };
