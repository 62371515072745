/* eslint react/jsx-key: off */
import PropTypes from 'prop-types';
import { DateField, Identifier, ReferenceField, Show, SimpleShowLayout, TextField } from 'react-admin';
import { JsonDataViewField } from '../logs/JsonDataField';

const SubscriptionViewEmbedded = ({ id }: { id?: Identifier }) => {
  return (
    <Show id={id} queryOptions={{ refetchInterval: 5000 }}>
      <SimpleShowLayout>
        <TextField source="id" />
        <ReferenceField source="customer_id" reference="user" link="show">
          <TextField source="display_name" />
        </ReferenceField>
        <TextField source="product_name" label="Product name" />
        <TextField source="subscription_interval" label="Interval" />
        <TextField source="subscription_period" label="Period" />
        <TextField source="status" label="Status" />
        <DateField source="start_date" showTime locales="de-AT" />
        <DateField source="next_payment_date" showTime locales="de-AT" />
        <DateField source="expired_date" showTime locales="de-AT" />
        <DateField source="cancelled_date" showTime locales="de-AT" />
        <DateField source="end_date" showTime locales="de-AT" />
        <JsonDataViewField customFieldName="meta_data" label="Meta data" />
      </SimpleShowLayout>
    </Show>
  );
};

SubscriptionViewEmbedded.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
  id: PropTypes.string,
};

export default SubscriptionViewEmbedded;
