/* eslint react/jsx-key: off */
import { Datagrid, List, TextField } from 'react-admin';

const ExtensionList = () => {
  return (
    <List>
      <Datagrid rowClick="expand" optimized>
        <TextField source="id" />
        <TextField source="name" label="Name" />
      </Datagrid>
    </List>
  );
};

export default ExtensionList;
