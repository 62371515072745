import PropTypes from 'prop-types';
import {
  DateField,
  FunctionField,
  Identifier,
  ReferenceField,
  ReferenceOneField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import { TenantExtensionField } from '../user-extension/TenantExtensionField';

const CaldavViewEmbedded = ({ id }: { id?: Identifier }) => {
  return (
    <Show id={id} queryOptions={{ refetchInterval: 5000 }}>
      <SimpleShowLayout>
        <TextField source="id" />
        <ReferenceField source="tenant_id" reference="tenant_mini_server_view" label="Mini-server" link="show">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="user_id" reference="user" label="User" link="show">
          <TextField source="display_name" />
        </ReferenceField>
        <ReferenceOneField
          target="calendar_id"
          reference="tenant_user_extension"
          label="Subscription - Extension"
          source="id"
        >
          <TenantExtensionField />
        </ReferenceOneField>
        <TextField source="type" label="Type" />
        <TextField source="calendar_id" label="Calendar id" />
        <TextField source="name" label="Name" />
        <FunctionField
          label="Color"
          render={(record: any) => <div style={{ backgroundColor: record.color, width: '20px', height: '20px' }} />}
          style={{ display: 'inline-block', maxWidth: '20em', overflow: 'hidden', textOverflow: 'ellipsis' }}
        />
        <TextField source="sync_interval" label="Sync interval" />
        <DateField source="synced_at" showTime locales="de-AT" />
        <DateField source="updated_at" showTime locales="de-AT" />
      </SimpleShowLayout>
    </Show>
  );
};

CaldavViewEmbedded.propTypes = {
  record: PropTypes.object,
  resource: PropTypes.string,
  id: PropTypes.string,
};

export default CaldavViewEmbedded;
