import PeopleIcon from '@mui/icons-material/People';
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';

export const UserIcon = PeopleIcon;

const GuestList = () => {
  return (
    <List
      filters={[
        <TextInput label="Guest name, email" source="guest_name,email" alwaysOn />,
        <ReferenceInput alwaysOn source="tenant_id" reference="tenant_mini_server_view" label="Mini server">
          <SelectInput source="name" label="Mini server" />
        </ReferenceInput>,
      ]}
      sort={{ field: 'updated_at', order: 'ASC' }}
    >
      <Datagrid bulkActionButtons={false} rowClick="show" optimized>
        <TextField source="id" />
        <ReferenceField source="tenant_id" reference="tenant_mini_server_view" label="Mini-server" link="show">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="guest_name" />
        <TextField source="code" />
        <TextField source="email" />
        <TextField source="sb_guest_id" label="Smoobu guest id" />
        <DateField source="updated_at" showTime locales="de-AT" />
      </Datagrid>
    </List>
  );
};

export default GuestList;
